.tiles-container > * {
	flex-grow: 1;
}

.recent-tickets-container > *,
.weather-holiday-container > * {
	width: 100%;
	height: 100%;
}

@media (min-width: 1000px) {
	.recent-tickets-container,
	.weather-holiday-container {
		flex-direction: row;
		align-items: stretch;
	}

	.recent-tickets-container > *,
	.weather-holiday-container > * {
		width: auto;
		height: auto;
	}

	.recent-tickets-container > :last-child {
		flex-grow: 0;
		flex-shrink: 0;
	}
}
