.day-forecast__row {
	--table__row--hover---background-color: #f8f8f8;
}

.day-forecast__row + .day-forecast__row {
	border-top: 1px solid #eee;
}

.day-forecast__cell {
	vertical-align: middle;
	font-size: 1rem;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
}

.day-forecast__info-icon {
	font-size: 0.75rem;
	border-radius: 50%;
	background-color: #ddd;
	--size: 1rem;
	width: var(--size);
	height: var(--size);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: default;
}

.day-forecast__temp-bound {
	font-size: 0.75rem;
}

.day-forecast__temp-bound__label {
	color: var(--label---color);
}
