.labeled-value__label {
	color: var(--label---color);
	font-size: calc(var(--label---font-size) * var(--labeled-value---scale));
	font-weight: var(--label---font-weight);
	font-family: var(--label---font-family);
	text-transform: var(--label---text-transform);
}

.labeled-value__value {
	font-size: calc(1.1rem * var(--labeled-value---scale));
}
