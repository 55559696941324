html {
	--roadium-orange: #f1965a;
	--roadium-green: #75a397;
	--roadium-purple: #bd72df;
	--roadium-yellow: #f5bd6a;
	--roadium-red: #eb392c;

	/* define theme CSS custom properties here */
	--header__bar---height: 3.5rem;
	--header__bar__navigation-toolbar---width: 100vw;
	--header__bar__navigation-toolbar---height: 1.75rem;
	--header__drawer---width: 90vw;
	--header__side-bar---width: 13rem;
	/* --header---height is used by other elements that depend on the header, the header's height is set internally. */
	--header---height: 0px;

	--general---theme-color: var(--roadium-green);
	--general---theme-text-color: #0a0a0a;

	--header---color: var(--general---theme-text-color);
	--header---background-color: var(--general---theme-color);
	--footer---color: var(--general---theme-text-color);
	--footer---background-color: var(--general---theme-color);
	--general__header---color: var(--general---theme-text-color);
	--general__header---background-color: var(--general---theme-color);

	--heading--level-1---margin: 0 0 1rem;

	--nav-link--active---color: var(--roadium-red);
	--internal-link--active---color: var(--roadium-red);

	font-family: sans-serif;
}

body {
	--table__header---top: var(--header---height);

	color: var(--general---color);
	background-color: var(--general---theme-color);

	--scroll-target---scroll-margin-top: calc(var(--header---height) + var(--floating-elements--top---height, 0px));
	--scroll-target---scroll-margin-bottom: var(--floating-elements--bottom---height, 0px);
	--table__scroll-target---scroll-margin-top: var(--scroll-target---scroll-margin-top);
	--table__scroll-target---scroll-margin-bottom: var(--scroll-target---scroll-margin-bottom);
}

body.body--with-header {
	--header---height: var(--header__bar---height);
	--pop-up---top: calc(var(--header---height) + 1rem);

	padding-top: var(--header---height);
}

body.body--with-header.body--with-navigation-toolbar {
	--header---height: calc(var(--header__bar---height) + var(--header__bar__navigation-toolbar---height));
}

body.body--with-header-side-bar {
	--pop-up---right: calc(var(--header__side-bar---width) + 1rem);
	--header__bar__navigation-toolbar---width: calc(100vw - var(--header__side-bar---width));

	padding-right: var(--header__side-bar---width);
}

@media print {
	body,
	body.body--with-header,
	body.body--with-header.body--with-navigation-toolbar {
		--header---height: 0px;
	}
}

main {
	width: 100%;
	min-height: 100vh;
}

body.body--with-header main {
	min-height: calc(100vh - var(--header---height));
}

.scroll-target {
	scroll-margin-top: var(--scroll-target---scroll-margin-top);
	scroll-margin-bottom: var(--scroll-target---scroll-margin-bottom);
}

.fit-content {
	width: fit-content;
}

.print-only {
	display: none;
}

@media print {
	.no-print {
		display: none;
	}

	.print-only {
		display: initial;
	}
}

.tile-background {
	background-color: #f2f2f2;
}

@media print {
	.tile-background {
		background-color: #fff;
	}
}

.tile {
	background-color: #fff;
	--section---border-radius: 1rem;
}

.tile__header {
	padding: 0.5rem 1rem;
	border-bottom: 2px solid var(--roadium-green);
}

.tile__footer {
	padding: 0 0.75rem 0.5rem;
}
