.tickets-summary-stats-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 2rem;
}

@media (min-width: 1100px) {
	.tickets-summary-stats-container {
		flex-direction: row;
		justify-content: space-around;
	}
}
