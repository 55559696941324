.hub-list {
	list-style-type: none;

	padding-left: 1rem;
}

.hub-list__item {
	margin-bottom: 1rem;
}

.hub-list__item__link {
	font-size: 1.25rem;

	margin-bottom: 0.25rem;

	display: inline-block;
}
