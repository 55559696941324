.print-page-break {
	display: none;
}

@media print {
	.print-page-break {
		display: block;
		break-after: page;
	}
}
