/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__O1JZnuIk {
	width: var(--select-field---width);
}

.multi-select__a32duebt {
	width: var(--multi-select-field---width);
}

.select__field__mYh6to4a,
.multi-select__field__9pTWWqbv {
	width: 100%;
}

.market-entrance-pricing-structure-form__column__2e39OksD {
	width: 100%;
}

.pricing-table__S1QCloRu {
	--table__header---background-color: #fffc;

	--table__row---background-color: transparent;
	--table__row--hover---background-color: var(--table__row---background-color);
}

.pricing-table__header__cgRJonCp {
	backdrop-filter: blur(2px) saturate(180%);
	border-bottom: 1px solid #eee;
	z-index: 10;
}

.table__heading--column-heading__Ya4fCYEh {
	display: inline-block;
}

.pricing-table__row--section-heading__0PPR27zG {
	--table__row---background-color: #e8e8e8;

	padding: 0.5rem 0;
}

.pricing-table__row--sub-section-heading__S9017Srh {
	--table__row---background-color: #f4f4f4;

	padding: 0.25rem 0;
}

.pricing-table__row--section-heading__0PPR27zG,
.pricing-table__row--sub-section-heading__S9017Srh {
	--table__row--hover---background-color: var(--table__row---background-color);
	--table__row--stripe---background-color: var(--table__row---background-color);
}

.pricing-table__row--first-in-sub-section__dksFha-b .pricing-table__cell--form-field__SZluzwZR {
	padding-top: 0.5rem;
}

.pricing-table__row--last-in-sub-section__X2RA1-MN .pricing-table__cell--form-field__SZluzwZR {
	padding-bottom: 0.5rem;
}

.pricing-table__html-heading--category--level-4__IbKVg58s {
	margin-left: 0.25rem;
}

.pricing-table__html-heading--category--level-5__ovXH3tCi {
	margin-left: 0.5rem;
}

.pricing-table__cell--form-field__SZluzwZR {
	padding-bottom: 0.25rem;

	overflow: visible;

	--number-field---width: 15rem;
}

/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__uny4kWSG {
	width: var(--select-field---width);
}

.multi-select__sHi3yjdw {
	width: var(--multi-select-field---width);
}

.select__field__mlR0rydX,
.multi-select__field__14ZzeIZU {
	width: 100%;
}

/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__dfNFisaz {
	width: var(--select-field---width);
}

.multi-select__hm-f7hhZ {
	width: var(--multi-select-field---width);
}

.select__field__4AyUM-Au,
.multi-select__field__Jb4qMRwV {
	width: 100%;
}

/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__dFUfV1U- {
	width: var(--select-field---width);
}

.multi-select__setvdybh {
	width: var(--multi-select-field---width);
}

.select__field__W8WGNMBf,
.multi-select__field__satzPRxA {
	width: 100%;
}

/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__MHKTbUCX {
	width: var(--select-field---width);
}

.multi-select__QzQH8-2L {
	width: var(--multi-select-field---width);
}

.select__field__qTtba7ye,
.multi-select__field__p7c6kWlf {
	width: 100%;
}

/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__17dyyEQg {
	width: var(--select-field---width);
}

.multi-select__ujdacXxF {
	width: var(--multi-select-field---width);
}

.select__field__rrML75Vl,
.multi-select__field__-zPSOOi3 {
	width: 100%;
}

/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__th41jiKA {
	width: var(--select-field---width);
}

.multi-select__7adbqikt {
	width: var(--multi-select-field---width);
}

.select__field__KeovRhY2,
.multi-select__field__xFvthahk {
	width: 100%;
}

/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.select__Eiqec9vv {
	width: var(--select-field---width);
}

.multi-select__P7yKXY-V {
	width: var(--multi-select-field---width);
}

.select__field__RyovQXW9,
.multi-select__field__xEP9Kh3- {
	width: 100%;
}

.price-calculator__hint__nLwQ-A5h {
	text-align: center;
	font-size: 0.9rem;
	background-color: #ddd;

	--size: 1.25rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.price-calculator__results__dbJOYzad {
	margin: 1.5rem auto 0;
	--section__body---padding: 2rem 1rem 1rem;
}

.price-calculator__results__table__yKHZDix8 {
	border-spacing: 0;
	max-width: 45rem;
}

.price-calculator__results__table__footer__cell__dLPyoWTv {
	border-top: 1px solid #111;
}
