.recent-tickets-chart__form__metric {
	width: fit-content;
}

.recent-tickets-chart__form__metric > :first-child {
	display: none;
}

.recent-tickets-chart__form__metric > :nth-child(2) {
	padding: 0;
	border: none;
}
