/* Code generated by @hex-insights/frontend-generator; While this comment is present this file may be overwritten. If manual edits are made, remove this comment. */

.auth-page__main {
	background-color: var(--general---theme-color);

	display: flex;
	justify-content: center;
	align-items: center;
}

.auth-page__content-container {
	background-color: var(--general---background-color);

	width: fit-content;
	padding: 2rem 3rem;
	border-radius: 1rem;
}
